
import { computed, defineComponent } from 'vue'

import { IonProgressBar } from "@ionic/vue";

import CreateService from "../../lib/service/CreateService";

export default defineComponent({
    props: {
        step: {
            type: Number,
            required: true
        }
    },
    components: {
        IonProgressBar
    },
    setup(props) {
        const steps = CreateService.calculateSteps();
        const value = computed(() => props.step / steps);
        return {
            steps,
            value
        }
    }
})
