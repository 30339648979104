
import { computed, defineComponent } from "vue";
import {
  IonPage,
  IonItem,
  IonInput,
  IonLabel,
  IonContent,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonButton,
  IonIcon,
  IonCheckbox,
} from "@ionic/vue";
import { arrowBack, home, arrowForward, pencil } from "ionicons/icons";

import CreateHeader from "./CreateHeader.vue";
import CreateProgressBar from "./CreateProgressBar.vue";
import ErrorMessage from "../generic/form/ErrorMessage.vue";

import LanguageService from "../../lib/service/LanguageService";
import CreateService from "../../lib/service/CreateService";
import { ValidationResult } from "../../lib/form/Validation";
import { AuthMode } from "../../../../shared/protocol/Create";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "TitleInput",
  components: {
    IonPage,
    IonItem,
    IonInput,
    IonLabel,
    IonContent,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonButton,
    IonIcon,
    IonCheckbox,
    CreateHeader,
    ErrorMessage,
    CreateProgressBar,
  },
  setup() {
    const lang = LanguageService.getTranslator("create/title");
    const langGeneric = LanguageService.getTranslator("generic");
    const router = useRouter();

    const validator = computed<ValidationResult>(() => {
      if (CreateService.info.title.length <= 0) {
        return {
          valid: false,
        };
      } else if (CreateService.info.title.length < 2) {
        return {
          valid: false,
          message: "titleTooShort",
        };
      } else if (CreateService.info.title.length > 30) {
        return {
          valid: false,
          message: "titleTooLong",
        };
      }
      return {
        valid: true,
      };
    });

    const submit = () => {
      if (validator.value.valid) {
        router.push("/create/modules");
      }
    };

    const setAuth = (auth: AuthMode) => {
      CreateService.info.auth = auth;
    };

    return {
      lang,
      langGeneric,
      arrowBack,
      home,
      arrowForward,
      pencil,
      CreateService,
      valid: true,
      validator,
      submit,
      setAuth,
    };
  },
  watch: {
    $route(to) {
      if (to.path === "/create/title") {
        CreateService.reset();
      }
    },
  },
});
