
import { defineComponent } from "vue";

import { homeOutline, arrowBack } from "ionicons/icons";

import {
  IonHeader,
  IonToolbar,
  IonIcon,
  IonBackButton,
  IonButton,
  IonTitle,
  IonButtons,
  alertController,
} from "@ionic/vue";

import { useRouter } from "vue-router";
import CreateService from "../../lib/service/CreateService";
import LanguageService from "../../lib/service/LanguageService";

export default defineComponent({
  name: "CreateHeader",
  components: {
    IonHeader,
    IonToolbar,
    IonIcon,
    IonBackButton,
    IonButton,
    IonButtons,
    IonTitle,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    hasBack: {
      type: Boolean,
      default: true,
    },
    backTo: {
      type: String,
    },
  },
  setup() {
    const router = useRouter();
    const lang = LanguageService.getTranslator("create/discard");
    const showDiscard = async () => {
      if (CreateService.info.title.length > 0) {
        const alert = await alertController.create({
          header: lang.t("discard"),
          message: lang.t("confirmDiscard"),
          buttons: [
            {
              role: "no",
              text: lang.t("abort"),
            },
            {
              role: "destructive",
              text: lang.t("discard"),
            },
          ],
        });
        await alert.present();
        const result = await alert.onDidDismiss();
        if (result.role === "destructive") {
          CreateService.reset();
          router.push("/home");
        }
      } else {
        CreateService.reset();
        router.push("/home");
      }
    };

    return {
      homeOutline,
      showDiscard,
      arrowBack,
    };
  },
});
